import React, { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import { PlayerRevenue } from '../../../types'
import { formatNumber, getShortAddress, getShortUsername } from '../../../shared/utils'

const Player: FC<Props> = ({ player }) => {
  return (
    <Stack direction={'row'} width={'100%'} alignItems={'center'}>
        <img src={require('../../../assets/img/vip/vip1_badge.png')} alt='player_badge' width={'50px'} height={'50px'} />
        <Stack direction={'column'} ml={2}>
            <Typography variant='h6' lineHeight={0}>{player.username ? getShortUsername(player.username) : getShortAddress(player.address)}</Typography>
            <Typography variant='subtitle2' marginTop={'10px'}>{formatNumber(player.value)} $ESTAR</Typography>
        </Stack>
    </Stack>
  )
}

interface Props {
    player: PlayerRevenue
}

export default Player