import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { CopyrightOutlined, Favorite } from '@mui/icons-material'

const Footer: FC = () => {
  return (
    <Typography
        variant='h4'
        sx={{ mt: 4, display: 'flex', alignItems: 'center', pb: '32px', fontSize: { xs: '14px', sm: '1rem', md: '1.5rem' } }}
    >
        Made with <Favorite sx={{ color: '#FFC300', mx: 1 }} /> by <CopyrightOutlined sx={{ ml: 1 }} /> ESTAR.GAMES - {new Date().getFullYear()}
    </Typography>
  )
}

export default Footer