import React, { FC } from 'react';
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton
} from '@multiversx/sdk-dapp/UI';
import { Box, Typography } from '@mui/material';
import { DefiWallet, Ledger, WebWallet, XPortal } from '../../assets/icons/login';
import { walletConnectV2ProjectId } from '../../config';

const LoginMethod: FC<Props> = ({ name, Icon }) => {
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Icon width={20} height={20} />
      <Typography variant={'body1'} color="black" sx={{ ml: 1 }}>{name}</Typography>
    </Box>
  )
}

interface Props {
  name: string;
  Icon: any
}

export const ConnectAddress = ({
  callbackRoute,
  children,
}: {
  callbackRoute: string;
  children?: React.ReactNode;
}) => {
  const commonProps = {
    callbackRoute: callbackRoute,
    nativeAuth: true
  };

  return (
    <Box className='login-methods' display={'flex'} flexDirection={'column'}>
        <ExtensionLoginButton
          loginButtonText='MultiversX DeFi Wallet'
          className='login-button d-block mx-auto mb-2'
          {...commonProps}
        >
          <LoginMethod name='MultiversX DeFi Wallet' Icon={DefiWallet}/>
        </ExtensionLoginButton>
        <WebWalletLoginButton
          loginButtonText='MultiversX Web Wallet'
          className='login-button d-block mx-auto mb-2'
          {...commonProps}
        >
          <LoginMethod name='MultiversX Web Wallet' Icon={WebWallet}/>
        </WebWalletLoginButton>
        <LedgerLoginButton
          loginButtonText='Ledger'
          className='login-button d-block mx-auto mb-2'
          {...commonProps}
        >
          <LoginMethod name='Ledger Wallet' Icon={Ledger}/>
        </LedgerLoginButton>
        <WalletConnectLoginButton
          loginButtonText='xPortal App'
          className='login-button d-block mx-auto'
          {...commonProps}
          {...(walletConnectV2ProjectId
            ? {
                isWalletConnectV2: true
              }
            : {})}
        >
          <LoginMethod name='xPortal App' Icon={XPortal}/>
        </WalletConnectLoginButton>
        {children}
    </Box>
  );
};
