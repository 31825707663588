import { routeNames } from '../../../routes';
import { AuthRedirect } from '../AuthRedirect';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AuthErrorMessages, Chains } from '../../../types';
import accountStore from '../../../store/AccountStore';
import ConnectAddress from '../../../components/ConnectAddress';

const MultiversxPage = observer(() => {
  const { address } = useGetAccountInfo();

  const login = async () => {
    try {
      await accountStore.login('multiversx', address);
    } catch (err: any) {
      if (err.response?.data.message === AuthErrorMessages.ADDRESS_NOT_FOUND) {
        register()
        return;
      }
    }
  };

  const register = async () => {
    try {
      await accountStore.register({ username: address, chain: Chains.MULTIVERSX, address });
    } catch (error: any) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (!address || address === null || address === '') return;
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return (
    <>
      <ConnectAddress callbackRoute={routeNames.login} />
    </>
  );
});

export const Multiversx = () => (
  <AuthRedirect>
    <MultiversxPage />
  </AuthRedirect>
);
