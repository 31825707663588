import axios from 'axios';
import { ESTAR_API } from '../../config';
import { Account } from '../types';
import { ApiEndpoints } from '../../types/enums';
import { signToken } from '../../shared/utils';

export class AccountService {
  async login(chain: string, address: string): Promise<{ token: string }> {
    try {
      const token = await signToken(address);
      const { data } = await axios.post(ESTAR_API + ApiEndpoints.LOGIN, {
        chain,
        address: address
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      throw err;
    }
  }

  async register(dto: {
    username: string;
    chain: string;
    address: string;
  }): Promise<{ token: string }> {
    try {
      const token = await signToken(dto);
      const { data } = await axios.post(ESTAR_API + ApiEndpoints.REGISTER, {
        ...dto
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getUser(token: string): Promise<Account> {
    try {
      const { data } = await axios.get(ESTAR_API + ApiEndpoints.GET_USER, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getUserProfileImage(token: string): Promise<any> {
    try {
      const { data } = await axios.get(ESTAR_API + ApiEndpoints.GET_USER_PROFILE_IMAGE, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getUserTokens(token: string): Promise<any> {
    try {
      const { data } = await axios.get(ESTAR_API + ApiEndpoints.GET_USER_TOKENS, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getUserNfts(token: string): Promise<any> {
    try {
      const { data } = await axios.get(ESTAR_API + ApiEndpoints.GET_USER_NFTS, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      throw err;
    }
  }

  // TO DO: Add types & insert in config api url
  async getUserItems(address: string): Promise<any> {
    try {
      if(!address) return [];
      const { data } = await axios.get(`https://estar.games:4000/users/${address}/items`);
      return data;
    } catch (err) {
      return []
    }
  }

  async getUserAchievements(address: string): Promise<any> {
    try {
      if(!address) return [];
      const { data } = await axios.get(`https://estar.games:4000/users/${address}/achievements`);
      return data;
    } catch (err) {
      return []
    }
  }

  async getUserRevenue(token: string): Promise<any> {
    try {
      const { data } = await axios.get(ESTAR_API + ApiEndpoints.GET_USER_REVENUE, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      return [];
    }
  }
  async linkAddress(
    token: string,
    chain: string,
    address: string
  ): Promise<Account> {
    const { data } = await axios.patch(
      ESTAR_API + ApiEndpoints.LINK_ADDRESS,
      {
        chain,
        address
      },
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
    return data;
  }
}
