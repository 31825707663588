// import { useGoogleLogin } from '@react-oauth/google';
import { observer } from 'mobx-react-lite';
// import accountStore from '../../../store/AccountStore';
import { useEffect } from 'react';
// import { AuthErrorMessages } from '../../../types/enums';
import { AuthRedirect } from '../AuthRedirect';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
// import { Google } from '../../../assets/icons/login';
import { Multiversx } from './Multiversx';
// import RegisterModal from '../../../components/RegisterModal';
import loginImage from '../../../assets/img/login.jpg';
import { useSearchParams } from 'react-router-dom';


const LoginPage = observer(() => {
  const [search] = useSearchParams();

  // const [googleToken, setGoogleToken] = useState<string>('');
  // const [show, setShow] = useState<boolean>(false);
  // const login = useGoogleLogin({
  //   onSuccess: async (response) => {
  //     setGoogleToken(response.access_token);
  //     try {
  //       await accountStore.login('google', response.access_token);
  //     } catch (err: any) {
  //       if (
  //         err.response?.data.message === AuthErrorMessages.ADDRESS_NOT_FOUND
  //       ) {
  //         setShow(true);
  //         return;
  //       }
  //     }
  //   }
  // });

  useEffect(() => {
    const token = search.get('accessToken');
    if(token) {
      localStorage.setItem('xportalToken', token);
    }
  }, [search])

  return (
    <Box sx={{ background: '#000000', paddingX: 2, minHeight: '100vh' }}>
      <Container maxWidth='xl'>
        <Navbar />
          <Paper sx={{ background: '#ffff', borderRadius: '60px', p: 4, my: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box>
                    <Typography variant='h2' fontWeight={900} color={'black'} align='center'>
                      Welcome
                    </Typography>
                    <Typography variant='h6' color={'black'} align='center'>
                      Welcome to ESTAR.GAMES dashboard
                    </Typography>
                   {/* <Button
                    variant='outlined'
                    sx={{ display: 'block', my: 2, mx: 'auto', borderColor: '#D2D2D2', textTransform: 'none' }}
                    onClick={() => login()}
                   >
                       <Box display={'flex'} alignItems={'center'}>
                        <Google width={20} height={20} />
                        <Typography variant='body1' color={'black'} align='center' sx={{ ml: 1 }}>
                          Login with Google
                        </Typography>
                      </Box> 
                    </Button>
                    <Divider textAlign='center' sx={{ mb: 2 }}>Or</Divider> */}
                    <Multiversx />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ position: 'relative' }}>
                  <img
                    src={loginImage}
                    alt='estar_login'
                    width={'100%'}
                    height={'500px'}
                    style={{ borderRadius: '60px' }}
                  />
                  <Typography variant='h4' align='center' sx={{
                    mt: 2, position: 'absolute', top: "50%", left: "50%",
                    transform: 'translate(-50%, -50%)',
                  }}>
                    <Typography component={'span'} variant='h4' sx={{ color: '#FFC300' }}>
                      ESTAR.GAMES
                    </Typography> is an gaming ecosystem
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        <Footer />
      </Container>
      {/* <RegisterModal show={show} setShow={() => setShow(false)} address={googleToken} /> */}
    </Box>
  );
});

export const Login = () => (
  <AuthRedirect>
    <LoginPage />
  </AuthRedirect>
);
