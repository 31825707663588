import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import NotLoggedInRedirect from '../../components/NotLoggedInRedirect';
import { Layout } from '../../components';
import SwapContent from './components/SwapContent';
import { Box, Typography } from '@mui/material';
import accountStore from '../../store/AccountStore';


export const SwapPage: FC = observer(() => {

  React.useEffect(() => {
    accountStore.getUserTokens()
}, [])

  return (
    <Box position={'relative'} mt={10}>
      <Box position={'absolute'} sx={{
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%)'
      }}>
        <SwapContent />
        <Typography variant='h5' textAlign={'center'} mt={3}>
          Powered by <Typography variant='h5' component={'a'} href="https://jexchange.io" target='_blank' rel="noreferrer">
                      JEXCHANGE
                     </Typography>
        </Typography>
      </Box>
    </Box>
  );
});

export const Swap = () => {
  return (
    <Layout pageTitle='Swap'>
      <NotLoggedInRedirect>
        <SwapPage />
      </NotLoggedInRedirect>
    </Layout>
  )
}
