import ReactDOM from 'react-dom/client';
import './assets/sass/theme.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import './index.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<ThemeProvider theme={theme}><App /></ThemeProvider>);
