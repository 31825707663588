import React, { FC } from 'react'
import { Box, SwipeableDrawer, useMediaQuery } from '@mui/material'
import { Global } from '@emotion/react';
import { SwipeUpAlt } from '@mui/icons-material';
import Menu from '../../Menu';
import { drawerBleeding } from '../../../shared/constants';

const BottomBar: FC = () => {
  const [open, setOpen] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);

  const mobile = useMediaQuery('(max-width:600px)');
  const toggleDrawer = () => {
    if(open === false) {
      setOpen(true);
      setTimeout(() => {
        setShowMenu(true);
      }, 200)
    } else {
      setOpen(false);
      setTimeout(() => {
        setShowMenu(false);
      }, 200)
    }
  };

  return (
    <Box height={'100%'}>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: mobile ? `calc(20% - ${drawerBleeding}px)` : '100%',
            overflow: 'visible',
            background: '#181818'
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        allowSwipeInChildren
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ display: { xs: 'block', sm: 'none' } }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -54,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            paddingY: '10px',
            backgroundColor: '#181818',
          }}
        >
          <SwipeUpAlt sx={{ color: 'white', fontSize: '35px' }} onTouchStart={toggleDrawer} />
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
            display: showMenu ? 'block' : 'none'
          }}
        >
          <Menu />
        </Box>
      </SwipeableDrawer>
    </Box>
  )
}

export default BottomBar