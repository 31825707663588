import React, { FC } from 'react'
import { List, ListItem, IconButton, Box, SvgIconTypeMap, Tooltip } from '@mui/material';
import { routes } from '../../routes'
import { useLocation, Link } from 'react-router-dom';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { checkIfUrlIsActive } from '../../shared/utils';

const MenuItem: FC<MenuItemProps> = ({ path, Icon, name, comingSoon, current = false, displayInSidebar}) => {

    if (!displayInSidebar) return null
    if(comingSoon) return (
        <ListItem>
            <Tooltip title={name} placement='right' arrow>
                <IconButton size='large' sx={{ background: current ? '#E3A102' : 'unset', '&:hover': {
                    background: current ? '#E3A102' : 'unset'
                } }}>
                    <Icon sx={{ color: current ? '#FFFFFF' : 'rgba(255, 255, 255, 0.4)' }} />
                </IconButton>
            </Tooltip>
        </ListItem>
    )
    return (
        <ListItem>
            <Link to={path}>
                <Tooltip title={name} placement='right' arrow>
                    <IconButton size='large' sx={{ background: current ? '#E3A102' : 'unset', '&:hover': {
                        background: current ? '#E3A102' : 'unset'
                    } }}>
                        <Icon sx={{ color: current ? '#FFFFFF' : 'rgba(255, 255, 255, 0.4)' }} />
                    </IconButton>
                </Tooltip>
            </Link>
        </ListItem>
    )
}

interface MenuItemProps {
    path: string
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    name: string
    title?: string
    current?: boolean
    displayInSidebar?: boolean
    comingSoon?: boolean
}

const Menu: FC = () => {
    const location = useLocation()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <List sx={{ display: { xs: 'flex', sm: 'unset' } }}>
        {routes.map((route, index) => (
            <MenuItem key={route + '-' + index} {...route} current={checkIfUrlIsActive(location, route.path)}/>
        ))}
        </List>
    </Box>
  )
}

export default Menu