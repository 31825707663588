import { ReactComponent as EquistarLogo } from "../../../assets/svgs/equistar.svg";
import { ReactComponent as ApcLogo } from "../../../assets/svgs/apc.svg";
import { LootBox } from "../../../types";

const revealData: LootBox[] = [
    {
        id: 'APC_LOOTBOX',
        key: {
            identifier: 'EASSETS-e516d7',
            nonce: 13,
            amount: 1
        },
        name: 'APC',
        icon: ApcLogo,
        smartContract: 'erd1qqqqqqqqqqqqqpgq3alq46rjyvkgn23gha6hsly8d9eqqf2ntusq2xea4n',
        image: 'https://media.elrond.com/nfts/asset/bafybeicworbmcq3iejnxmzmsc3boodllgul2iqeqd6saz2ya6hwrhhxgbi/item.png',
        video: '/apc.mp4',
        available: true,
        prizes: [
            {
                id: 1,
                type: 'SFT',
                name: 'Angry Penguins Eggs',
                amount: 1
            },
            {
                id: 2,
                type: 'SFT',
                name: 'Hook',
                amount: 1
            },
            {
                id: 3,
                type: 'SFT',
                name: 'Flower',
                amount: 1
            },
            {
                id: 4,
                type: 'SFT',
                name: 'Bandana',
                amount: 1
            },
            {
                id: 5,
                type: 'SFT',
                name: 'Toothpick',
                amount: 1
            },
            {
                id: 6,
                type: 'SFT',
                name: 'Yellow Feathers',
                amount: 1
            },
            {
                id: 7,
                type: 'SFT',
                name: 'Tiger',
                amount: 1
            },
            {
                id: 8,
                type: 'SFT',
                name: 'Crocodile',
                amount: 1
            },
            {
                id: 9,
                type: 'SFT',
                name: 'Diamond',
                amount: 1
            },
            {
                id: 10,
                type: 'SFT',
                name: 'Skin Inverted',
                amount: 1
            },
            {
                id: 11,
                type: 'SFT',
                name: 'Black Skin',
                amount: 1
            },
            {
                id: 12,
                type: 'SFT',
                name: 'Green Background',
                amount: 1
            },
            {
                id: 13,
                type: 'SFT',
                name: 'Coral Reef Background',
                amount: 1
            },
            {
                id: 14,
                type: 'SFT',
                name: 'Purple Background',
                amount: 1
            },
            {
                id: 15,
                type: 'SFT',
                name: 'Turquoise Background',
                amount: 1
            },
            {
                id: 16,
                type: 'SFT',
                name: 'Surfboard',
                amount: 1
            },
            {
                id: 17,
                type: 'SFT',
                name: 'Spear',
                amount: 1
            },
            {
                id: 18,
                type: 'SFT',
                name: 'Sea Shepherd Gaff',
                amount: 1
            },
            {
                id: 19,
                type: 'SFT',
                name: 'Fishing Rifle',
                amount: 1
            },
            {
                id: 20,
                type: 'SFT',
                name: 'Axe',
                amount: 1
            },
            {
                id: 21,
                type: 'SFT',
                name: 'Nest',
                amount: 1
            },
            {
                id: 22,
                type: 'SFT',
                name: 'Cowboy',
                amount: 1
            },
            {
                id: 23,
                type: 'SFT',
                name: 'Chapka',
                amount: 1
            },
            {
                id: 24,
                type: 'SFT',
                name: 'Propeller Cap',
                amount: 1
            },
            {
                id: 25,
                type: 'SFT',
                name: 'White EGLD Cap',
                amount: 1
            },
            {
                id: 26,
                type: 'SFT',
                name: 'Black Bitcoin Cap',
                amount: 1
            },
            {
                id: 27,
                type: 'SFT',
                name: 'Black APC Cap',
                amount: 1
            },
            {
                id: 28,
                type: 'SFT',
                name: 'Horns',
                amount: 1
            },
            {
                id: 29,
                type: 'SFT',
                name: 'Beret',
                amount: 1
            },
            {
                id: 30,
                type: 'TOKEN',
                name: 'ESTAR',
                amount: 250
            },
            {
                id: 31,
                type: 'TOKEN',
                name: 'OURO',
                amount: 0.5
            },
            {
                id: 32,
                type: 'TOKEN',
                name: 'USDC',
                amount: 5
            },
            {
                id: 33,
                type: 'TOKEN',
                name: 'WEGLD',
                amount: 0.3
            },
            {
                id: 34,
                type: 'UNLUCKY',
                name: 'UNLUCKY',
                amount: 0.3
            },
        ]
    },
    {
        id: 'EQUISTAR_LOOTBOX',
        key: {
            identifier: 'EASSETS-e516d7',
            nonce: 1,
            amount: 1
        },
        name: 'EQUISTAR',
        icon: EquistarLogo,
        smartContract: '',
        image: 'https://media.elrond.com/nfts/asset/bafybeiaygzesmylvseq6o4xskiiv3uxmox56jqjb7qa5bnncos4edoqp2i/item.png',
        video: '',
        available: false,
        prizes: [
            {
                id: 0,
                type: '',
                name: '',
                amount: 0
            }
        ]
    }
]


export default revealData;
