import React from 'react'
import { Drawer, IconButton } from '@mui/material'
import { Logo } from '../../../assets/icons'
import { sidebarWidth } from '../../../shared/constants'
import Menu from '../../Menu'
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom'
import { routeNames } from '../../../routes';

const Sidebar = () => {
  return (
    <Drawer variant='permanent' sx={{
        display: { xs: 'none', sm: 'block'},
        width: sidebarWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          boxSizing: 'border-box',
          background: '#181818',
          borderRadius: '0px 32px 32px 0px'
        },
      }}>
        <Link to={routeNames.home}>
          <IconButton sx={{ paddingTop: '25px', display: 'block', m: '0 auto' }}>
              <Logo width={50} height={50} />
          </IconButton>
        </Link>
        <Toolbar />
        <Menu />
    </Drawer>
  )
}

export default Sidebar