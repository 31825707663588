enum RequestStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL'
}

enum ApiEndpoints {
  REGISTER = '/auth/register',
  LOGIN = '/auth/login',
  GET_USER = '/users/me',
  GET_USER_PROFILE_IMAGE = '/users/me/profile-image',
  GET_USER_TOKENS = '/users/me/tokens',
  GET_USER_NFTS = '/users/me/nfts',
  GET_USER_REVENUE = '/users/me/revenue',
  LINK_ADDRESS = '/users/me/link-address',
  GET_SHOP_ITEMS = '/shop/items',
  BUY_ITEM = '/shop/buy',
  GET_TOP_REVENUE_USERS = '/revenue/top',
}

enum AuthErrorMessages {
  ADDRESS_NOT_FOUND = 'Address not found',
  USER_ALREADY_EXISTS = 'User exists!',
  USERNAME_ALREADY_EXISTS = 'This username is already taken!',
  EMAIL_ALREDY_EXISTS = 'This email is already taken!',
  ADDRESS_ALREDY_EXISTS = 'This address is already taken!'
}

enum Chains {
  GOOGLE = 'google',
  MULTIVERSX = 'multiversx'
}

enum ItemType {
  ONE_TIME = "one_time",
  SUBSCRIPTION = "subscription",
}

export { RequestStatus, AuthErrorMessages, ApiEndpoints, Chains, ItemType };
