import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Paper, Typography } from '@mui/material'
import { Diamonds, Logo, Shards } from '../../../../assets/icons'
import { observer } from 'mobx-react-lite';
import { xcastleTokens } from '../../../../shared/constants'
import WalletXCastleToken from './components/WalletXCastleToken'
import accountStore from '../../../../store/AccountStore'
import { MULTIVERSX_API, TOKEN_IDENTIFIER } from '../../../../config'
import { formatNumber, getVipBadge } from '../../../../shared/utils'
import { Chains } from '../../../../types';
import axios from 'axios';

const Wallet = observer(() => {
    const { account, tokens } = accountStore
    const [xPortalUsername, setXPortalUsername] = useState<string>();

    useEffect(() => {
        const getUsername = async () => {
            try {
                const { data } = await axios.get(MULTIVERSX_API + '/accounts/' + account?.connected.address);
                if(data.username) {
                    setXPortalUsername(data.username)
                }
            } catch (err: any) {
                console.log(err);
            }
        }
    
        if(account?.connected.chain === Chains.MULTIVERSX) {
            getUsername()
        }
    }, [account])

    useEffect(() => {
        accountStore.getUserTokens()
    }, [])

  return (
    <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={getVipBadge(account?.vip.level)} alt='user_vip_badge' width={46} height={46}/>
            <Typography variant={'h5'} fontWeight={'bold'}>VIP{account?.vip?.level}</Typography>
            <Typography variant={'h4'}>
                {
                    xPortalUsername !== undefined ? xPortalUsername.split('.elrond') : account?.username.startsWith('erd') ? 
                    account?.username.slice(0, 5) + "..." + account?.username.slice(-5)
                    : account?.username
                }
            </Typography>
        </Grid>
        <Paper sx={{ padding: 2, background: 'rgba(0, 0, 0, 0.8)', borderRadius: '10px' }}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={'h5'} align='center'>Wallet</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
                            <IconButton>
                                <Logo width={32} height={32}/>
                            </IconButton>
                            <Paper sx={{
                                    background: '#747474',
                                    height: '32px',
                                    minWidth: '80px',
                                    paddingX: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Typography variant='h6'>
                                    {formatNumber(tokens?.find(token => token.identifier === TOKEN_IDENTIFIER)?.balance || 0)}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
                            <IconButton>
                                <Shards width={32} height={32}/>
                            </IconButton>
                            <Paper sx={{
                                    background: '#747474',
                                    height: '32px',
                                    minWidth: '80px',
                                    paddingX: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Typography variant='h6'>
                                    {formatNumber(0)}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
                            <IconButton>
                                <Diamonds width={32} height={32}/>
                            </IconButton>
                            <Paper sx={{
                                    background: '#747474',
                                    height: '32px',
                                    minWidth: '80px',
                                    paddingX: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                            }}>
                                <Typography variant='h6'>
                                    {formatNumber(account?.diamonds || 0)}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'} align='center'>Tokenized Resources</Typography>
                </Grid>
                <Grid container spacing={1}>
                    {
                        xcastleTokens.map((item) => (
                            <Grid key={item.identifier} item xs={12} md={'auto'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <WalletXCastleToken item={item} />
                            </Grid>
                        ))
                    }
                </Grid>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
  )
})

export default Wallet
