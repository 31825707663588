import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import NotLoggedInRedirect from '../../components/NotLoggedInRedirect';
import { Layout } from '../../components';
import { Box, Grid, Paper } from '@mui/material';
import Image from './components/Profile/Image';
import Wallet from './components/Profile/Wallet';
import Invetory from './components/Profile/Invetory';
import Chart from './components/Revenue/Chart';
import Achievements from './components/Profile/Achievements';
import Games from '../../components/Games';
import TopPlayers from '../../components/TopPlayers';

export const DashboardPage: FC = observer(() => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ background: '#222325', padding: 4, borderRadius: '24px' }}>
            <Grid container spacing={6} alignItems={'end'}>
              <Grid item xs={12} md={3} lg={2}>
                <Image />
              </Grid>
              <Grid item xs={12} md={9} lg={6}>
                <Wallet />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Invetory />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Chart />
        </Grid>
        <Grid item xs={12} md={4}>
          <Achievements />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }} spacing={2}>
        <Grid item xs={12} md={8}>
          <Games />
        </Grid>
        <Grid item xs={12} md={4}>
          <TopPlayers />
        </Grid>
      </Grid>
    </Box>
  );
});

export const Dashboard = () => {
  return (
    <Layout pageTitle='Dashboard'>
      <NotLoggedInRedirect>
        <DashboardPage />
      </NotLoggedInRedirect>
    </Layout>
  )
}
