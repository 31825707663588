import { makeAutoObservable, runInAction } from 'mobx';
import { Account, UserAddress } from './types';
import Cookies from 'js-cookie';
import { AccountService } from './services/AccountService';
import { logout } from '@multiversx/sdk-dapp/utils';
import { googleLogout } from '@react-oauth/google';
import { getChainAddress } from '../shared/hooks/getChainAddress';

class AccountStore {
  account?: Account;
  // TO DO: add types for tokens, nfts and items
  tokens?: any[];
  nfts?: any[]
  items?: any[];
  revenue: any[] = [];
  profileImage?: string;
  achievements?: any[];
  accessToken?: string;
  isLoading: boolean = false;
  accountService: AccountService;

  constructor() {
    makeAutoObservable(this);
    const accountService = new AccountService();
    this.accountService = accountService;
  }

  async login(chain: string, address: string): Promise<string> {
    const { token } = await this.accountService.login(chain, address);
    console.log(address);
    if (typeof token === 'string') {
      this.setAccessToken(token);
      this.loadAccount();
    }
    return token;
  }

  async register(dto: {
    username: string;
    chain: string;
    address: string;
  }): Promise<string> {
    const { token } = await this.accountService.register(dto);

    if (typeof token === 'string') {
      this.setAccessToken(token);
      this.loadAccount();
    }
    return token;
  }

  async loadAccount(): Promise<void> {
    this.isLoading = true;
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      this.isLoading = false;
      return;
    }
    try {
      const account = await this.accountService.getUser(accessToken);
      if (!account) {
        this.isLoading = false;
        this.reset();
        return;
      }
      runInAction(() => {
        this.account = account;
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
    }
  }

  async getUserProfileImage(): Promise<any> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      throw new Error('Not logged in!');
    }
    const profileImage = await this.accountService.getUserProfileImage(accessToken);
    if (!profileImage) {
      throw new Error('Failed to get tokens');
    }
    runInAction(() => {
      this.profileImage = profileImage;
    });
    return profileImage;
  }

  async getUserTokens(): Promise<any> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      throw new Error('Not logged in!');
    }
    const tokens = await this.accountService.getUserTokens(accessToken);
    if (!tokens) {
      throw new Error('Failed to get tokens');
    }
    runInAction(() => {
      this.tokens = tokens;
    });
    return tokens;
  }

  async getUserNfts(): Promise<any> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      throw new Error('Not logged in!');
    }
    const nfts = await this.accountService.getUserNfts(accessToken);
    if (!nfts) {
      throw new Error('Failed to get nfts');
    }
    runInAction(() => {
      this.nfts = nfts;
    });
    return nfts;
  }

  async getUserItems(addresses: UserAddress[]): Promise<any> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      throw new Error('Not logged in!');
    }

    const chainAddress = getChainAddress(addresses, 'multiversx');
    if(!chainAddress) {
      throw new Error('No multiversx address found!');
    }

    const items = await this.accountService.getUserItems(chainAddress.address);

    runInAction(() => {
      this.items = items;
    });
  }

  async getUserAchievements(addresses: UserAddress[]): Promise<any> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      throw new Error('Not logged in!');
    }

    const chainAddress = getChainAddress(addresses, 'multiversx');
    if(!chainAddress) {
      this.achievements = [];
      throw new Error('No multiversx address found!');
    }

    const achievements = await this.accountService.getUserAchievements(chainAddress.address);
    if (!achievements) {
      runInAction(() => {
        this.achievements = [];
      });
      throw new Error('Failed to get achievements');
    }
    runInAction(() => {
      this.achievements = achievements;
    });
    return achievements;
  }

  async getUserRevenue(): Promise<any> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      throw new Error('Not logged in!');
    }
    const revenue = await this.accountService.getUserRevenue(accessToken);
    if (revenue.length === 0) {
      throw new Error('Failed to get revenue');
    }
    runInAction(() => {
      this.revenue = revenue;
    });
    return revenue;
  }


  async linkAddress(chain: string, address: string): Promise<boolean> {
    const accessToken = this.accessToken;
    if (!accessToken) {
      throw new Error('Not logged in!');
    }
    const account = await this.accountService.linkAddress(
      accessToken,
      chain,
      address
    );
    if (!account) {
      throw new Error('Failed to link address');
    }
    runInAction(() => {
      this.account = account;
    });
    return true;
  }

  setAccessToken(credential: string): void {
    const date = new Date();
    date.setTime(date.getTime() + 86_400 * 1000);
    Cookies.set('access_token', credential, { expires: date });
  }

  logOut(): void {
    logout();
    googleLogout();
    Cookies.remove('access_token');
    this.account = undefined;
    this.accessToken = undefined;
  }

  reset(): void {
    Cookies.remove('access_token');
    this.account = undefined;
    this.accessToken = undefined;
  }

  getAccessToken(): string | undefined {
    const access_token = Cookies.get('access_token');
    if (this.accessToken !== access_token) this.accessToken = access_token;
    return this.accessToken;
  }

  get isLoggedIn(): boolean {
    return this.account != null;
  }

  haveAddressAssociated(chain: string): boolean {
    if (!this.account) return false;
    for (const address of this.account?.addresses) {
      if (address.chain === chain) return true;
    }
    return false;
  }
}

const accountStore = new AccountStore();

export default accountStore;
