import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { API_TIMEOUT, googleId, walletConnectV2ProjectId } from './config';
import { routes } from './routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import accountStore from './store/AccountStore';
import { observer } from 'mobx-react-lite';
import { CssBaseline } from '@mui/material';

function App() {
  useEffect(() => {
    accountStore.loadAccount();
  }, []);

  return (
    <Router>
      <DappProvider
        environment={EnvironmentsEnum.mainnet}
        dappConfig={{
          shouldUseWebViewProvider: true
        }}
        customNetworkConfig={{
          name: 'customConfig',
          apiTimeout: API_TIMEOUT,
          walletConnectV2ProjectId,
        }}
      >
        <TransactionsToastList />
        <NotificationModal />
        <SignTransactionsModals className='custom-class-for-modals' />
        <GoogleOAuthProvider clientId={googleId}>
          <CssBaseline />
          <Routes>
            {routes.map((route, index) => (
              <Route
                path={route.path}
                key={'route-key-' + index}
                element={
                  <route.component />
                }
              />
            ))}
            {/* <Route path='*' element={<PageNotFound />} /> */}
          </Routes>
        </GoogleOAuthProvider>
      </DappProvider>
    </Router>
  );
}

export default observer(App);
