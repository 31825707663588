import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Grid } from '@mui/material';
import Navbar from '../../components/Navbar';
import Banner from './components/Banner';
import Games from '../../components/Games';
import TopPlayers from '../../components/TopPlayers';
import Footer from '../../components/Footer';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import accountStore from '../../store/AccountStore';
import { AuthErrorMessages, Chains } from '../../types';
import { useSearchParams } from 'react-router-dom';

const Home = observer(() => {
  const { address } = useGetAccountInfo();
  const [search] = useSearchParams();

  const login = async () => {
    try {
      await accountStore.login('multiversx', address);
    } catch (err: any) {
      if (err.response?.data.message === AuthErrorMessages.ADDRESS_NOT_FOUND) {
        register()
        return;
      }
    }
  };

  const register = async () => {
    try {
      await accountStore.register({ username: address, chain: Chains.MULTIVERSX, address });
    } catch (error: any) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (!address || address === null || address === '') return;
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    const token = search.get('accessToken');
    if(token) {
      localStorage.setItem('xportalToken', token);
    }
  }, [search])
  
  return (
    <Box sx={{ background: '#000000', paddingX: 2 }}>
      <Container maxWidth='xl'>
        <Navbar />
        <Box sx={{ mt: 1 }}>
            <Banner />
            <Grid container sx={{ mt: 1 }} spacing={2}>
                <Grid item xs={12} md={8}>
                  <Games />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TopPlayers />
                </Grid>
            </Grid>
        </Box>
        <Footer />
      </Container>
    </Box>
  );
});

export { Home };
