import React, { FC } from 'react'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import '../../../index.css';
import { TOKEN_IDENTIFIER } from '../../../config';

interface Props {
    token: string,
    list: string[],
    firstAmount: string,
    secondAmount: number,
    changeOrder: () => void,
    onChange: (amount: string) => void
    handleChangeToMax: () => void
}

const TokenInput: FC<Props> = ({ token, list, firstAmount, secondAmount, changeOrder, onChange, handleChangeToMax }) => {
  return (
    <>
        <div style={{margin: '20px 0'}}>
            <TextField
                id="outlined-basic"
                label={token === TOKEN_IDENTIFIER ? token.split('-')[0] : 'EGLD'}
                variant="outlined"
                value={token === list[0] ? firstAmount : secondAmount / Math.pow(10, 18)}
                onChange={token === list[0] ? (e) => onChange(e.target.value) : () => {}}
                color="primary"
                focused={true}
                type="number"
                className="custom-input"
                InputProps={{
                    startAdornment: <InputAdornment position="start">From</InputAdornment>,
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleChangeToMax}>
                        Max
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                />
            </div>
            {
                token === list[0] && <div className='swap-switch'>
                <Button onClick={changeOrder}><VerticalAlignCenterIcon color="primary" /></Button>
              </div>
            }
    </>
  )
}

export default TokenInput