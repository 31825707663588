import axios from 'axios';
import { ESTAR_API } from '../../config';
import { ApiEndpoints } from '../../types/enums';
import { ShopItem } from '../../types';

export class ShopService {
  async getItems(token: string): Promise<ShopItem[]> {
    try {
      const { data } = await axios.get(ESTAR_API + ApiEndpoints.GET_SHOP_ITEMS, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      throw err;
    }
  }

  async buyItem(id: string, token: string): Promise<string> {
    try {
      const { data } = await axios.post(ESTAR_API + ApiEndpoints.BUY_ITEM, {
        id
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return data;
    } catch (err) {
      throw err;
    }
  }
}
