import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import accountStore from '../store/AccountStore'
import { Navigate } from 'react-router-dom'
import { routeNames } from '../routes'
import { Loading } from './Loading'

const NotLoggedInRedirect: FC<Props> =observer(({ children }) => {
  if (accountStore.isLoading) return <Loading loadingText='Fetching account...' />;
  if(!accountStore.isLoggedIn) return <Navigate to={routeNames.login} />
  return (
    <>
        {children}
    </>
  )
})

interface Props {
    children: React.ReactNode
}

export default NotLoggedInRedirect