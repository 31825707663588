import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../index.css';

import { ReactComponent as Logo } from '../../../assets/icons/estar.svg';
import { TOKEN_IDENTIFIER } from '../../../config';
import TokenInput from './TokenInput';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import accountStore from '../../../store/AccountStore';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services'
import { numberToHex, stringToHex } from 'muf-utils';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#E3A102',
    },
  },
});

const TOKENS = [TOKEN_IDENTIFIER, 'WEGLD-bd4d79'];

const Swap: React.FC = observer(() => {
  const { tokens: accountTokens } = accountStore;
  const estarAmount = accountTokens?.find(token => token.identifier === TOKEN_IDENTIFIER)?.balance || 0;
  const [firstTokenAmount, setFirstTokenAmount] = React.useState('');
  const [secondTokenAmount, setSecondTokenAmount] = React.useState(0);
  const [gasFee, setGasFee] = React.useState(0);
  const [routePayload, setRoutePayload] = React.useState('');
  const [tokens, setTokens] = React.useState(TOKENS);
  
  const handleSwap = async () => {
    const data = `ESDTTransfer@${stringToHex(tokens[0])}@${numberToHex(Number(firstTokenAmount) * Math.pow(10, 18))}@${stringToHex('swapTokenFixedInput')}@${stringToHex(tokens[1])}@${numberToHex(secondTokenAmount)}@${routePayload.replace('x', '')}@${tokens[0] === TOKEN_IDENTIFIER ? '01' : '00'}`;
    await refreshAccount();

    const transactions = tokens[0] === TOKEN_IDENTIFIER ? [{
      value: 0,
      data,
      receiver: 'erd1qqqqqqqqqqqqqpgqqvs2jvf64wzcz2836er0j98l3ytshpcr5sns997aga',
      gasLimit: gasFee + 5000000
  }] : [
    {
      value: Number(firstTokenAmount) * Math.pow(10, 18),
      data: 'wrapEgld',
      receiver: 'erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3',
      gasLimit: '2500000'
    },
    {
      value: 0,
      data,
      receiver: 'erd1qqqqqqqqqqqqqpgqqvs2jvf64wzcz2836er0j98l3ytshpcr5sns997aga',
      gasLimit: gasFee + 5000000
    }
  ]
    await sendTransactions({transactions});
  };

  const handleOrderChange = () => {
    setTokens(tokens.slice().reverse())
  };

  const handleChangeAmount = (amount: string) => {
    if(tokens[0] === TOKEN_IDENTIFIER) {
      if(Number(amount) < 0 || Number(amount) > estarAmount) return;
    }
    if(Number(amount) < 0) return;
    setFirstTokenAmount(amount)
  }

  const handleChangeToMax = () => {
    setFirstTokenAmount(tokens[0] === TOKEN_IDENTIFIER ? estarAmount.toString() : '0')
  }

  React.useEffect(() => {
    const getTokenEquivalent = async () => {
      try {
        if(!Number(firstTokenAmount)) {
          setSecondTokenAmount(0);
          return;
        }
        const amount = BigInt(Number(firstTokenAmount) * Math.pow(10, 18))
        const { data } = await axios.get(`https://api.jexchange.io/routing/evaluations?token_in=${tokens[0]}&token_out=${tokens[1]}&amount_in=${amount}`)
        setSecondTokenAmount(Number(data[0].net_amount_out))
        setGasFee(Number(data[0].estimated_gas))
        setRoutePayload(data[0].route_payload?.replace("0x", ""))
      } catch (error) {
        console.log(error)
      }
    }
    getTokenEquivalent()
  }, [tokens, firstTokenAmount])

  return (
    <ThemeProvider theme={theme}>
     <Box className='custom-inputbox'
        component="form"
        sx={{
          '& > :not(style)': { m: 1 },
          color: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
         
        }}
        noValidate
        autoComplete="off"
      > 
        <Logo></Logo>
        <Typography variant="h4" component="div" gutterBottom>
          Swap <span className='estarspan'>$ESTAR</span>
        </Typography>
        {
          tokens.map((token, index) => (
            <TokenInput
              key={token + index}
              token={token}
              list={tokens}
              changeOrder={handleOrderChange}
              onChange={handleChangeAmount}
              handleChangeToMax={handleChangeToMax}
              firstAmount={firstTokenAmount}
              secondAmount={secondTokenAmount}
              />
          ))
        }
        <div style={{margin: '20px 0'}}>
          <Button variant="contained" color="secondary" onClick={handleSwap} disabled={!secondTokenAmount}>Swap</Button>
        </div>
      </Box>
    </ThemeProvider>
  );
});

export default Swap;