import React, { FC } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Box, IconButton, Tooltip, Badge, Stack } from '@mui/material';
import { Textsms, Notifications, Logout } from '@mui/icons-material';
import { sidebarWidth } from '../../../shared/constants';
import accountStore from '../../../store/AccountStore';

const Navbar: FC<Props> = ({ pageTitle }) => {
  return (
    <AppBar
        position="relative"
        color="primary"
        sx={{
            background: 'transparent',
            boxShadow: 'none',
            paddingTop: 2,
            paddingBottom: 2,
            zIndex: 2,
            width: { xs: '100%', sm: `calc(100% - ${sidebarWidth}px)`},
            ml: { xs: 0, sm: `${sidebarWidth}px`},
        }}
    >
        <Toolbar component={Box} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h2">
                {pageTitle}
            </Typography>
            <Stack display={'flex'} direction={'row'} spacing={{ xs: 0, md: 2 }}>
                <Tooltip title={'Chats (Coming Soon)'} placement='bottom' arrow>
                    <IconButton>
                        <Badge color={'success'} variant="dot">
                            <Textsms sx={{ color: '#E3A102' }} />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Notifications (Coming Soon)'} placement='bottom' arrow>
                    <IconButton>
                    <Badge color={'success'} variant="dot">
                        <Notifications sx={{ color: '#E3A102' }} />
                    </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Logout'} placement='bottom' arrow>
                    <IconButton onClick={() => accountStore.logOut()}>
                        <Logout sx={{ color: '#E3A102' }} />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Toolbar>
    </AppBar>
  )
}

interface Props {
    pageTitle: string
}

export default Navbar