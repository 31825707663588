import React from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';

export const Loading = ({ loadingText }: { loadingText?: string }) => {
  return (
    <Stack mt={5} width={'100%'} alignItems={'center'}>
        <CircularProgress sx={{ color: '#E3A102' }} />
        {loadingText && (
          <Typography variant='h4' mt={3} sx={{
            color: 'black'
          }} className='text-muted text-center'>{loadingText}</Typography>
        )}
    </Stack>
  );
};
