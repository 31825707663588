import { RouteType } from '@multiversx/sdk-dapp/types';
import { Home, Login, Dashboard, Shop, Swap, Reveal } from './pages';
import { Home as HomeIcon, Store, VideogameAsset, CompareArrows, Redeem } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export const routeNames = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',
  shop: '/shop',
  games: '/games',
  swap: '/swap',
  reveal: '/reveal'
};

type RouteTypeExtended = RouteType & {
  name: string;
  title?: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  displayInSidebar?: boolean;
  comingSoon?: boolean;
}

export const routes: RouteTypeExtended[] = [
  {
    path: routeNames.home,
    component: Home,
    name: 'Home',
    title: 'Home',
    Icon: HomeIcon,
    displayInSidebar: false
  },
  {
    path: routeNames.login,
    component: Login,
    name: 'Login',
    title: 'Login',
    Icon: HomeIcon,
    displayInSidebar: false
  },
  {
    path: routeNames.dashboard,
    component: Dashboard,
    name: 'Dashboard',
    title: 'Dashboard',
    Icon: HomeIcon,
    displayInSidebar: true
  },
  {
    path: routeNames.shop,
    component: Shop,
    name: 'Shop (Coming Soon)',
    title: 'Shop',
    Icon: Store,
    displayInSidebar: true,
    comingSoon: true
  },
  {
    path: routeNames.swap,
    component: Swap,
    name: 'Swap',
    title: 'Swap',
    Icon: CompareArrows,
    displayInSidebar: true
  },
  {
    path: routeNames.reveal,
    component: Reveal,
    name: 'Reveal',
    title: 'Reveal',
    Icon: Redeem,
    displayInSidebar: true
  },
  {
    path: routeNames.games,
    component: Shop,
    name: 'Games (Coming Soon)',
    title: 'Games',
    Icon: VideogameAsset,
    displayInSidebar: true,
    comingSoon: true
  }
];

// export const mappedRoutes = routes.map((route) => {
//   const title = route.title ? `${route.title} • EstarGames` : `EstarGames`;

//   const requiresAuth = Boolean(route.authenticatedRoute);
//   const wrappedComponent = withPageTitle(title, route.component);

//   return {
//     path: route.path,
//     component: wrappedComponent,
//     authenticatedRoute: requiresAuth
//   };
// });
