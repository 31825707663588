import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { shopStore } from '../../store/ShopStore'
import { Col, Row } from 'react-bootstrap'
import NotLoggedInRedirect from '../../components/NotLoggedInRedirect'
import { Loading } from '../../components/Loading'
import ShopItems from './components/ShopItems'
import { Layout } from '../../components'

export const ShopPage = observer(() => {
  const { items, isLoading } = shopStore;
  useEffect(() => {
    shopStore.fetchItems()
  }, [])

  if(isLoading) return <Loading loadingText='Fetching items...' />
  
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Shop</h1>
        </Col>
      </Row>
      <ShopItems items={items} />
    </>
  )
})

export const Shop = () => {
  return (
    <Layout pageTitle='Shop'>
      <NotLoggedInRedirect>
        <ShopPage />
      </NotLoggedInRedirect>
    </Layout>
  )
}