import React, { useState } from 'react'
import { Layout } from '../../components'
import NotLoggedInRedirect from '../../components/NotLoggedInRedirect'
import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import revealData from './data/reveal-data'
import LootBoxItem from './components/LootBoxItem'
import { LootBox } from '../../types'

const RevealPage = () => {
  const [lootBox, setLootBox] = useState<LootBox>(revealData[0]);

  const changeLootbox = (id: string) => {
    const lootboxFounded = revealData.find((lootbox) => lootbox.id === id);
    if(!lootboxFounded) return;
    setLootBox(lootboxFounded)
  } 
  return (
    <>
      <Box sx={{
          display: {
            xs: 'block',
            lg: 'none'
          },
          mt: 2,
          padding: 1
        }}
      >
        <Stack flexDirection='row' columnGap={2}>
          {
            revealData.map((lootbox) => (
              <Paper
                key={lootbox.id}
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  background: '#E3A102',
                  ...(
                    lootBox.id === lootbox.id && ({
                      background: '#FFFF'
                    })
                  )
                }}
                onClick={() => changeLootbox(lootbox.id)}
              >
                <Typography color={'black'} align='center'>
                  {lootbox.name}
                </Typography>
              </Paper>
            ))
          }
        </Stack>
        <Grid container mt={5}>
          <Grid item xs={12}>
            <LootBoxItem lootbox={lootBox} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{
          display: {
            xs: 'none',
            lg: 'block'
          }
        }}
      >
        <Grid container spacing={2}>
          {
            revealData.map((lootbox) => (
              <Grid item md={6} key={lootbox.id}>
                <lootbox.icon style={{
                  display: 'block',
                  margin: 'auto',
                  marginBottom: '20px',
                  color: '#E3A102',
                  height: '40px',
                  width: '160px'
                }} width={'100%'} />
                <LootBoxItem lootbox={lootbox} />
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </>
  )
}

const Reveal = () => {
    return (
      <Layout pageTitle='Reveal'>
        <NotLoggedInRedirect>
          <RevealPage />
        </NotLoggedInRedirect>
      </Layout>
    )
}

export default Reveal;