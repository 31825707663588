import { createTheme, responsiveFontSizes } from "@mui/material";

const defaultTheme = createTheme();

const breakpoints = createTheme(defaultTheme, {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
});

const theme = responsiveFontSizes(createTheme({
    // palette: {
    //     primary: {
            
    //     }
    // },
    typography: {
        fontFamily: 'Space Mono',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        h1: {
            fontSize: '2.5rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 500,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 400,
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 300,
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 200,
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 400,
        },
        subtitle2: {
            fontSize: '0.8rem',
            fontWeight: 300,
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 300,
        },
        [breakpoints.breakpoints.down('md')]: {
            h1: {
                fontSize: '1.75rem',
            },
            h2: {
                fontSize: '1.5rem',
            },
            h3: {
                fontSize: '1.25rem',
            },
            h4: {
                fontSize: '1rem',
            },
            h5: {
                fontSize: '0.875rem',
            },
            h6: {
                fontSize: '0.75rem',
            },
            subtitle1: {
                fontSize: '0.625rem',
            },
            subtitle2: {
                fontSize: '0.5rem',
            },
            body1: {
                fontSize: '0.625rem',
            },
            body2: {
                fontSize: '0.5rem',
            }
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#fff',
                }
            }
        }
    }
}));

export default theme;