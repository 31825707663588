import React, { FC, useEffect } from 'react'
import { Avatar, Box, Button, Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'
import accountStore from '../../../../store/AccountStore'
import { getVipFrame } from '../../../../shared/utils'
// import { Chains } from '../../../../types'
// import CheckAddressToLink from '../../../../components/CheckAddressToLink'

const Image: FC = observer(() => {
  const { profileImage, account } = accountStore

  useEffect(() => {
    accountStore.getUserProfileImage()
  }, [])

  return (
    <Stack alignItems={'center'} justifyContent={'center'}>
          <Box sx={{ position: 'relative' }}>
            <Avatar src={profileImage} variant='square' sx={{ height: '150px', width: '150px'}} />
            <img src={getVipFrame(account?.vip.level)} alt='user_vip_frame' width={'240px'} height={'210px'} style={{
              position: 'absolute',
              top: '-30px',
              left: '-45px',
            }}/>
          </Box>
        <Button variant='contained' sx={{ mt: '27px', width: '50%', background: 'rgba(0, 0, 0, 0.8)', '&:hover': {
            background: 'rgba(0, 0, 0, 0.8)',
        } }}>
            Edit
        </Button>
        {/* {Object.values(Chains).map((chain) => {
            return (
              <CheckAddressToLink
                key={chain}
                chain={chain}
                displayText={chain}
              />
            );
        })} */}
    </Stack>
  )
})

export default Image