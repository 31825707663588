import React, { FC } from 'react'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import homeBanner from '../../../assets/img/home_banner.jpg'
import { Star } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { routeNames } from '../../../routes'

const Banner: FC = () => {
  return (
    <Box sx={{ 
        backgroundImage: `url(${homeBanner})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingX: { xs: '30px', sm: '70px', md: '100px', lg: '131px' },
        paddingY: '50px',
        borderRadius: '24px',
     }}>
        <Paper
            sx={{
                background: 'rgba(0, 0, 0, 0.8)',
                border: '2px solid #F6CB2C',
                boxShadow: '4px 4px 20px #E3A102',
                borderRadius: '24px',
                paddingY: { xs: '15px', md: '30px'},
                paddingX: { xs: '20px', md: '40px'},
                maxWidth: '400px',
            }}
        >
            <Stack direction={'column'}>
                <Typography variant='h2' color={'#F6CB2C'} align='center'>JOIN FOR FREE</Typography>
                <Typography variant='body2' marginTop={'20px'}>
                    Combining the power of free to have fun with blockchain and play to earn
                </Typography>
                <Link to={routeNames.login} style={{ textDecoration: 'none' }}>
                    <Button sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        background: 'rgba(255, 255, 255, 0.05)',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        borderRadius: '12px',
                        marginTop: '20px',
                        marginX: 'auto',
                        width: 'fit-content',
                        textTransform: 'none',
                    }}>
                        <Star sx={{ color: '#F6CB2C' }} />
                        <Typography variant='h6' sx={{ marginLeft: '12px' }}>Register now</Typography>
                    </Button>
                </Link>
            </Stack>
        </Paper>
    </Box>
  )
}

export default Banner