import { SignJWT } from 'jose';
import { JWT_SECRET } from '../config';
import { type Location } from 'react-router-dom'

// VIP Frames
import vip1_frame from '../assets/img/vip/vip1_frame.png';
import vip2_frame from '../assets/img/vip/vip2_frame.png';
import vip3_frame from '../assets/img/vip/vip3_frame.png';
import vip4_frame from '../assets/img/vip/vip4_frame.png';
import vip5_frame from '../assets/img/vip/vip5_frame.png';
import vip6_frame from '../assets/img/vip/vip6_frame.png';
import vip7_frame from '../assets/img/vip/vip7_frame.png';
import vip8_frame from '../assets/img/vip/vip8_frame.png';

// VIP Badges
import vip1_badge from '../assets/img/vip/vip1_badge.png';
import vip2_badge from '../assets/img/vip/vip2_badge.png';
import vip3_badge from '../assets/img/vip/vip3_badge.png';
import vip4_badge from '../assets/img/vip/vip4_badge.png';
import vip5_badge from '../assets/img/vip/vip5_badge.png';
import vip6_badge from '../assets/img/vip/vip6_badge.png';
import vip7_badge from '../assets/img/vip/vip7_badge.png';
import vip8_badge from '../assets/img/vip/vip8_badge.png';

/**
 * Check if the current URL is an active one, by the location pathname.
 * Note that it will return true for sub-routes, i.e. url `/services` is active for pathname `/services/32`.
 * @param {Location}  location  Location, best provided by the `useLocation` hook from `react-router` library.
 * @param {string}    url       URL to check against, whether it's active or not.
 */
const checkIfUrlIsActive = (location: Location, url: string): boolean => {
  if (['/'].includes(url)) return location.pathname === url
  return location.pathname.startsWith(url)
}

const signToken = async (payload: any): Promise<string> => {
    return await new SignJWT({
        ...payload,
    })
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setExpirationTime('1m')
    .sign(new TextEncoder().encode(JWT_SECRET));
}

const getCurrencySymbol = (currency: string): string => {
    switch (currency) {
        case 'fiat':
            return '$';
        case 'estar':
            return '$ESTAR';
        case 'bnb':
            return '$BNB';
        default:
            return '';
    }
}

const formatNumber = (number: number): string => {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

const formatNumberToLog10 = (number: number): string => {
    if(number <= 0) return `${number}k`;
    const suffixes = ["", "k", "M", "B", "T"];
    const magnitude = Math.floor(Math.log10(number) / 3);
    const scaledNumber = number / Math.pow(10, magnitude * 3);
    const roundedNumber = Math.round(scaledNumber * 10) / 10;
    
    return `${roundedNumber}${suffixes[magnitude]}`;
}

const getVipFrame = (level: number | undefined): string => {
    switch (level) {
        case 1:
            return vip1_frame;
        case 2:
            return vip2_frame;
        case 3:
            return vip3_frame;
        case 4:
            return vip4_frame;
        case 5:
            return vip5_frame;
        case 6:
            return vip6_frame;
        case 7:
            return vip7_frame;
        case 8:
            return vip8_frame;
        default:
            return vip1_frame;
    }
}

const getVipBadge = (level: number | undefined): string => {
    switch (level) {
        case 1:
            return vip1_badge;
        case 2:
            return vip2_badge;
        case 3:
            return vip3_badge;
        case 4:
            return vip4_badge;
        case 5:
            return vip5_badge;
        case 6:
            return vip6_badge;
        case 7:
            return vip7_badge;
        case 8:
            return vip8_badge;
        default:
            return vip1_badge;
    }
}

const getShortAddress = (address: string): string => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

const getShortUsername = (username: string): string => {
    if(username.length > 15) {
        return `${username.slice(0, 6)}...${username.slice(-4)}`;
    }

    return username;
}

export {
    checkIfUrlIsActive,
    signToken,
    getCurrencySymbol,
    formatNumber,
    getVipFrame,
    getVipBadge,
    formatNumberToLog10,
    getShortAddress,
    getShortUsername
}