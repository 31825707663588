import React, { FC, useEffect } from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJs,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
} from 'chart.js'
import { observer } from 'mobx-react-lite'
import accountStore from '../../../../store/AccountStore'
import { formatNumberToLog10 } from '../../../../shared/utils'

ChartJs.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
)

const Chart: FC = observer(() => {
    const { revenue } = accountStore
    
    useEffect(() => {
        accountStore.getUserRevenue()
    }, [])

    const data = {
        labels: revenue?.map((item: any) => item.date),
        datasets: [{
            data: revenue?.map((item: any) => item.value),
            backgroundColor: '#34C759',
            borderColor: '#34C759',
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            tension: 0.5
        }]
    }   
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: 'rgba(255, 255, 255, 0.6)'
                }
            },
            y: {
                ticks: {
                    stepSize: 3000,
                    callback: (value: any) => '$ ' + formatNumberToLog10(value),
                    color: 'rgba(255, 255, 255, 0.6)'
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.1)'
                }
            }
        }
    }

    return (
        <Paper sx={{ background: '#181818', padding: 4, borderRadius: '24px', marginTop: '24px', height: '100%' }}>
            <Box width={'100%'} sx={{ maxHeight: '570px' }}>
                <Typography variant={'h4'} color={'white'} mb={'20px'}>Total $ESTAR Revenue</Typography>
                <Line data={data} options={options} width={500} height={500} style={{ 
                    marginBottom: '20px',
                }} />
            </Box>
        </Paper>
      )
})

export default Chart