import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import accountStore from '../../../../../store/AccountStore'
import { EQUISTAR_URL } from '../../../../../config'

const AchievementsList: FC = observer(() => {
    const { achievements } = accountStore

  if(!achievements) return <Typography>Loading...</Typography>
  if(achievements?.length === 0) return <Typography>No achievements</Typography>
  return (
    <Grid container spacing={2}>
            {
              achievements?.map((achievement, index) => (
                <Grid key={index} item xs={6} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={EQUISTAR_URL + '/' + achievement.path} alt="placeholder" width={'100px'} />
                </Grid>
              ))
            }
    </Grid>
  )
})

export default AchievementsList