import React, { FC, useEffect } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import accountStore from '../../../../store/AccountStore';
import itemsImage from '../../../../assets/img/items.png'
import nftsImage from '../../../../assets/img/nfts.png'

const Invetory: FC = observer(() => {
    const { nfts, items } = accountStore
    const { account } = accountStore
    useEffect(() => {
        accountStore.getUserNfts()
        if(!account?.addresses) return
        accountStore.getUserItems(account?.addresses)
    }, [account])

  return (
    <Paper sx={{ background: 'rgba(0, 0, 0, 0.8)', p: '10px', borderRadius: '10px' }}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper sx={{ background: '#747474', padding: '10px', borderRadius: '10px' }}>
                    <Box>
                        <Typography variant={'h6'} align='center'>NFTs</Typography>
                        <Typography variant={'body1'} align='center'>{nfts?.length || 0}</Typography>
                        <Paper sx={{
                                background: 'rgba(0, 0, 0, 0.8)',
                                padding: '3px',
                                borderRadius: '10px',
                                height: '110px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <img src={nftsImage} alt="nfts" />
                        </Paper>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper sx={{ background: '#747474', padding: '10px', borderRadius: '10px' }}>
                    <Box>
                        <Typography variant={'h6'} align='center'>Inventory Items</Typography>
                        <Typography variant={'body1'} align='center'>{items?.length || 0}</Typography>
                        <Paper sx={{
                                background: 'rgba(0, 0, 0, 0.8)',
                                padding: '3px',
                                borderRadius: '10px',
                                height: '110px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <img src={itemsImage} alt="items" />
                        </Paper>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Paper>
  )
})

export default Invetory