import React, { FC, useEffect } from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import accountStore from '../../../../store/AccountStore'
import AchievementsList from './components/AchievementsList'

const Achievements: FC = observer(() => {
  const { account } = accountStore

  useEffect(() => {
    if(!account?.addresses) return
    accountStore.getUserAchievements(account?.addresses)
  }, [account])
  return (
    <Paper sx={{ background: '#181818', padding: 4, borderRadius: '24px', marginTop: '24px', height: '100%' }}>
      <Box>
          <Typography variant={'h4'} mb={'20px'} align='center'>Achievements</Typography>
          <AchievementsList />
      </Box>
    </Paper>
  )
})

export default Achievements