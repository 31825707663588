import React, { FC } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Box, Grid } from '@mui/material';
import { drawerBleeding, sidebarWidth } from '../../shared/constants';
import BottomBar from './BottomBar';

export const Layout: FC<Props> = ({ children, pageTitle }) => {
  return (
    <Box sx={{ background: '#000000', minHeight: '100vh'}}>
      <Navbar pageTitle={pageTitle} />
      <Sidebar />
      <Box
        sx={{
            marginLeft: {xs: '0', sm: `${sidebarWidth + 20}px`},
            padding: { xs: 1, md: 4 },
            paddingBottom: {xs: `${drawerBleeding}px`, sm: 0}
          }}>
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
      <BottomBar />
    </Box>
  );
};

interface Props {
  pageTitle: string;
  children: React.ReactNode;
}
