import React, { FC } from 'react'
import GameItem from './components/GameItem'
import { Grid, Paper, Typography } from '@mui/material'
import { gamesList } from '../../shared/constants'

const Games: FC = () => {
  const xportalToken = localStorage.getItem('xportalToken');
  console.log(xportalToken)
  return (
    <Paper sx={{ background: '#222325', padding: '24px',  borderRadius: '24px' }}>
        <Typography variant='h4' marginBottom={2}>Games</Typography>
        <Grid container spacing={2}>
            {gamesList.map((game, index) => (
                <Grid item xs={12} sm={6} md={4} key={game.name + '-' + index}>
                    {
                      game.link ? <a href={
                        xportalToken !== null ? game.link + '?accessToken=' + xportalToken : game.link
                      } target='_blank' rel='noreferrer'>
                        <GameItem game={game} />
                      </a> : <GameItem game={game} />
                    }
                </Grid>
            ))}
        </Grid>
    </Paper>
  )
}

export default Games