import { Address } from '@multiversx/sdk-core';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { Dispatch, SetStateAction, useState } from 'react';
import { Key } from '../../../types';
import { numberToHex, stringToHex } from 'muf-utils';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

export const useOpenLootbox = (
  key: Key,
  smartContract: string
): {
  sendOpenLootBoxTransaction: () => Promise<void>;
  transactionSessionId: string;
  setTransactionSessionId: Dispatch<SetStateAction<string>>;
} => {
  const { address } = useGetAccountInfo();
  const [transactionSessionId, setTransactionSessionId] = useState<string>('');

  const sendOpenLootBoxTransaction = async (): Promise<void> => {
    console.log(
      key.identifier,
      key.nonce,
      key.amount,
      smartContract,
      'openLootbox'
    );
    const transaction = {
      value: 0,
      gasLimit: '5000000',
      data:
        'ESDTNFTTransfer@' +
        stringToHex(key.identifier) +
        '@' +
        numberToHex(key.nonce) +
        '@' +
        numberToHex(key.amount) +
        '@' +
        new Address(smartContract).hex() +
        '@' +
        stringToHex('openLootbox'),
      receiver: address
    };
    await refreshAccount();

    const { sessionId } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occured during',
        successMessage: 'Transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  return {
    sendOpenLootBoxTransaction,
    transactionSessionId,
    setTransactionSessionId
  };
};
