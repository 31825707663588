import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ShopItem } from '../../../types'
import { Col, Row } from 'react-bootstrap'
import BuyItemButton from './BuyItemButton'
import { getCurrencySymbol } from '../../../shared/utils'
import { getItemPrice } from '../hooks'

const ShopItems: FC<Props> = observer(({ items }) => {
  return (
    <Row className={'mt-2'}>
    {
          items.map(item => (
            <Item key={item.id} item={item} />
          ))
        }
    </Row>
  )
})

const Item: FC<ShopItemProps> = ({ item }) => {
    const currency = getCurrencySymbol(item.currency[0])
    return (
        <Col md={4} className='mb-4'>
            <div className='card text-dark'>
                <img src={item.image} alt={item.name} className='card-img-top' width="100%" />
                <div className='card-body'>
                  <h5 className='card-title text-capitalize'>{item.amount} {item.name}</h5>
                  <p className='card-text'>{item.description}</p>
                  <p className='card-text'>{getItemPrice(item.price, item.amount)} {currency}</p>
                  <BuyItemButton item={item} />
                </div>
            </div>
        </Col>
    )
}

interface Props {
    items: ShopItem[]
}

interface ShopItemProps {
    item: ShopItem
}

export default ShopItems