import { VideogameAsset } from "@mui/icons-material";
import { GameItemI, xCastleToken } from "../types";

import equistar from '../assets/img/equistar.jpg'
import xcastle from '../assets/img/xcastle.jpg'
import egoal from '../assets/img/egoal.jpg'
import angryPenguins from '../assets/img/angrypenguins.jpg'
import xarena from '../assets/img/xarena.jpg'
import comingSoon from '../assets/img/comingsoon.jpg'
import { Beer, Bread, Eccu, Iron, Shield, Stone, Wood } from "../assets/icons/inventory";

const sidebarWidth = 96;

const drawerBleeding = 70;

const gamesList: GameItemI[] = [
    {
        name: 'equistar',
        image: equistar,
        Icon: VideogameAsset,
        stage: 'MVP - Early Stage',
        link: 'https://equistar.estar.games'
    },
    {
        name: 'xcastle',
        image: xcastle,
        Icon: VideogameAsset,
        stage: 'MVP - Early Stage',
        link: 'https://xcastle.estar.games'
    },
    {
        name: 'egoal',
        image: egoal,
        Icon: VideogameAsset,
        stage: 'Announced'
    },
    {
        name: 'angry penguins colony',
        image: angryPenguins,
        Icon: VideogameAsset,
        stage: 'Customizable NFT',
        link: 'https://marketplace.angrypenguinscolony.com'
    },
    {
        name: 'xarena',
        image: xarena,
        Icon: VideogameAsset,
        stage: 'NFT Arena',
        link: 'https://xarena.estar.games'
    },
    {
        name: 'coming soon',
        image: comingSoon,
        Icon: VideogameAsset,
        stage: 'TBA'
    },
]

const xcastleTokens: xCastleToken[] = [
    {
        identifier: 'FOOD-46261d',
        Icon: Bread
    },
    {
        identifier: 'WOOD-2f70ee',
        Icon: Wood
    },
    {
        identifier: 'BEER-093bf2',
        Icon: Beer
    },
    {
        identifier: 'IRON-abe3cd',
        Icon: Iron
    },
    {
        identifier: 'STONE-6830a4',
        Icon: Stone
    },
    {
        identifier: 'WARGEAR-932f1d',
        Icon: Shield
    },
    {
        identifier: 'ECCU-29891f',
        Icon: Eccu
    },
]

export {
    sidebarWidth,
    gamesList,
    xcastleTokens,
    drawerBleeding
}