import axios from "axios"
import { MULTIVERSX_API } from "../../../config";

export const getUserTransactions = async (address: string, smartContract: string) => {
    try {
        if(!smartContract) return [];
        const { data } = await axios.get(
            `${MULTIVERSX_API}/accounts/${address}/transactions?sender=${address}&receiver=${smartContract}&withScResults=true&status=success`
            );
        return data;
    } catch (error) {
        console.log(error);
    }
}