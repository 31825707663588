import React, { FC } from 'react'
import { ShopItem } from '../../../types'
import { buyItemWithEstar } from '../hooks'
import { observer } from 'mobx-react-lite'
import { shopStore } from '../../../store/ShopStore'

const BuyItemButton: FC<Props> = observer(({ item }) => {
  if(item.currency[0] === 'estar') return (
    <button className='btn btn-primary' onClick={() => buyItemWithEstar(item)}>Buy</button>
  )

  return (
    <button className='btn btn-primary' onClick={() => shopStore.buyItem(item.id)}>Buy</button>
  )
})

interface Props {
    item: ShopItem
}

export default BuyItemButton