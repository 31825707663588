import React, { FC, useEffect } from 'react'
import { List, ListItem, Paper, Skeleton, Typography } from '@mui/material'
import Player from './components/Player'
import getTopRevenueUsers from '../../shared/hooks/getTopRevenueUsers'
import { PlayerRevenue } from '../../types'

const TopPlayers: FC = () => {
    const [topPlayers, setTopPlayers] = React.useState<PlayerRevenue[]>([])
    useEffect(() => {
        getTopRevenueUsers().then((res) => setTopPlayers(res)).catch((err) => console.log(err))
    }, [])

  if(topPlayers.length === 0) return (
    <Paper sx={{ background: '#222325', padding: '24px',  borderRadius: '24px' }}>
        <Typography variant='h4' marginBottom={2}>Top Players</Typography>
        <List>
            {
                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => (
                    <ListItem sx={{ pt: '10px', pl: 0 }} key={index}>
                        <Skeleton variant="circular" width={49} height={49} sx={{ mr: 1 }} />
                        <Skeleton variant="rectangular" width={"100%"} height={49} />
                    </ListItem>
                ))
            }
        </List>
    </Paper>
  )

  return (
    <Paper sx={{ background: '#222325', padding: '24px',  borderRadius: '24px' }}>
        <Typography variant='h4' marginBottom={1}>Top Players</Typography>
        <List>
            {
                topPlayers.map((player, index) => (
                    <ListItem sx={{ pt: '10px', pl: 0 }} key={player.address + '-' + index}>
                        <Player player={player} />
                    </ListItem>
                ))
            }
        </List>
    </Paper>
  )
}

export default TopPlayers