import React, { FC, useEffect, useState } from 'react'
import { LootBox } from '../../../types'
import { Box, Button, Typography } from '@mui/material'
import axios from 'axios'
import { MULTIVERSX_API } from '../../../config'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { numberToHex } from 'muf-utils'
import { useOpenLootbox } from '../hooks/useOpenLootbox'
import CardResult from './CardResult'
import TransactionsLog from './TransactionsLog'
import Rewards from './Rewards'

interface Props {
    lootbox: LootBox
}

const LootBoxItem: FC<Props> = ({ lootbox }) => {
    const { address } = useGetAccountInfo();
    const [keyBalance, setKeyBalance] = useState(0);
    const { sendOpenLootBoxTransaction, transactionSessionId, setTransactionSessionId } = useOpenLootbox(lootbox.key, lootbox.smartContract);

    useEffect(() => {
       const getKeyInfo = async () => {
            try {
                const identifier = lootbox.key.identifier + '-' + numberToHex(lootbox.key.nonce)
                const { data } = await axios.get(`${MULTIVERSX_API}/accounts/${address}/nfts/${identifier}`);
                setKeyBalance(Number(data.balance))
            } catch (error) {
                console.log(error)
            }
       }
       getKeyInfo();
    }, [address, lootbox])

  return (
    <>
        <Box sx={{
            maxWidth: '400px',
            display: 'block',
            mx: 'auto'
        }}>
            <Box position={'relative'}>
                <img src={lootbox.image} alt={lootbox.name} width={'100%'} height={'100%'} style={{
                    borderRadius: '24px',
                    border: '2px solid #E3A102'
                }} />
                <Typography
                    variant='h4'
                    fontWeight={900}
                    position={'absolute'}
                    bottom={8}
                    left={'50%'}
                    textAlign={'center'}
                    sx={{
                        transform: 'translateX(-50%)',
                        background: '#E3A102',
                        width: '70px',
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px'
                    }}
                >x{keyBalance}</Typography>
            </Box>
            <Button
                variant='contained'
                sx={{
                    display: 'block',
                    mx: 'auto',
                    mt: '20px',
                    background: '#E3A102',
                    "&:hover": {
                        background: '#E3A102'
                    },
                    "&.Mui-disabled": {
                        color: '#FFFF',
                        background: '#373837'
                    }
                }}
                onClick={sendOpenLootBoxTransaction}
                disabled={lootbox.available === false || keyBalance < 1}
            >{lootbox.available ? 'Reveal' : 'Coming Soon'}</Button>
        </Box>
        <Rewards smartContract={lootbox.smartContract} lootboxKey={lootbox.key} />
        <TransactionsLog smartContract={lootbox.smartContract} prizes={lootbox.prizes} />
        <CardResult sessionId={transactionSessionId} setSessionId={setTransactionSessionId} video={lootbox.video} prizes={lootbox.prizes} />
    </>
  )
}

export default LootBoxItem