import React, { FC } from 'react'
import { observer } from 'mobx-react-lite';
import { IconButton, Typography, Paper } from '@mui/material';
import { xCastleToken } from '../../../../../types';
import accountStore from '../../../../../store/AccountStore';
import { formatNumber } from '../../../../../shared/utils';

const WalletXCastleToken: FC<Props> = observer(({ item }) => {
    const { tokens } = accountStore
  return (
    <>
        <IconButton>
            <item.Icon width={25} height={25}/>
        </IconButton>
        <Paper sx={{
            background: '#747474',
            height: '25px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 5px'
        }}>
            <Typography variant='h6' align='center'>{
                formatNumber(tokens?.find(token => token.identifier === item.identifier)?.balance || 0)
            }</Typography>
        </Paper>
    </>
  )
})

interface Props {
    item: xCastleToken
}

export default WalletXCastleToken