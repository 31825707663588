import React from 'react';
import { observer } from 'mobx-react-lite';
import accountStore from '../../store/AccountStore';
import { Navigate } from 'react-router-dom';
import { routeNames } from '../../routes';

export const AuthRedirect = observer(
  ({ children }: { children: React.ReactNode }) => {
    if (accountStore.isLoggedIn) return <Navigate to={routeNames.dashboard} />;
    return <>{children}</>;
  }
);
