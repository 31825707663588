import { makeAutoObservable, runInAction } from "mobx";
import { ShopItem } from "../types";
import { ShopService } from './services/ShopService';
import Cookies from "js-cookie";

class Shop {
    items: ShopItem[] = [];
    shopService: ShopService
    isLoading: boolean = false;
    accessToken?: string;
    constructor() {
        const shopService = new ShopService();
        makeAutoObservable(this);
        this.shopService = shopService;
    }

    async fetchItems() {
        try {
            this.isLoading = true;
            const data = await this.shopService.getItems(this.getAccessToken());
            runInAction(() => {
                this.items = data;
                this.isLoading = false;
            })
        } catch (err) {
            this.isLoading = false;
            throw err;
        }
    }

    async buyItem(id: string) {
        try {
            const data = await this.shopService.buyItem(id, this.getAccessToken());
            window.open(data, '_self');
            return data;
        } catch (err) {
            throw err;
        }
    }

    getAccessToken(): string {
        const access_token = Cookies.get('access_token');
        if (this.accessToken !== access_token) this.accessToken = access_token;
        if(!this.accessToken) throw new Error('No access token');

        return this.accessToken;
    }
}

export const shopStore = new Shop();