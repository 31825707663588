import React, { FC } from 'react'
import { AppBar, Box, Button, Stack, Toolbar, Typography, useMediaQuery } from '@mui/material'
import { EstarGamesLogo } from '../../assets/icons'
import { User } from '../../assets/icons/home'
import { Link } from 'react-router-dom'
import { routeNames } from '../../routes'
import { observer } from 'mobx-react-lite'
import accountStore from '../../store/AccountStore'

const Navbar: FC = observer(() => {
    const { isLoggedIn } = accountStore;
    const mobile = useMediaQuery('(max-width:600px)');
    const tablet = useMediaQuery('(max-width:900px)');
    const logoWidth = mobile ? 90 : tablet ? 120 : 197;
    const logoHeight = mobile ? 35 : tablet ? 40 : 76;

    const leftIconsWidth = mobile ? 20 : tablet ? 24 : 28;
  return (
    <AppBar
        position="relative"
        color="primary"
        sx={{
            background: 'transparent',
            boxShadow: 'none',
            paddingY: 2,
            zIndex: 2
        }}
    >
        <Toolbar disableGutters component={Box} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
            <Stack direction={'row'} alignItems={'center'}>
                <Link to={routeNames.home}>
                    <EstarGamesLogo width={logoWidth} height={logoHeight} />
                </Link>
                <Typography variant="h2" sx={{ marginLeft: { xs: '5px', md: '24px'} }}>
                    Hub
                </Typography>
            </Stack>
            <Link to={isLoggedIn ? routeNames.dashboard : routeNames.login} style={{  textDecoration: 'none' }}>
                <Button
                        sx={{
                            border: '1px solid rgba(255, 255, 255, 0.2)',
                            background: 'rgba(255, 255, 255, 0.05)',
                            borderRadius: '12px',
                            color: '#F6CB2C',
                            height: { xs: '30px', md: '40px' },
                            textTransform: 'none',
                        }}
                    >
                        <Stack direction={'row'} alignItems={'center'}>
                            <User width={leftIconsWidth} height={leftIconsWidth} />
                            {!isLoggedIn && (
                                <Typography 
                                    color={'#F6CB2C'}
                                    sx={{
                                        fontSize: { xs: '15px', md: '20px'},
                                        marginLeft: { xs: '5px', md: '16px'},
                                    }}
                                >
                                    Sign In
                                </Typography>
                            )}
                        </Stack>
                </Button>
            </Link>
        </Toolbar>
    </AppBar>
  )
})

export default Navbar