import React, { FC, SetStateAction, useEffect } from 'react'
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'
import { Dispatch } from 'react';
import { Loading } from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { decodeBase64 } from '@multiversx/sdk-dapp/utils';
import { MULTIVERSX_API } from '../../../config';
import { hexToNumber } from 'muf-utils';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import { Prize } from '../../../types';

const CardResult: FC<Props> = observer(({ sessionId, setSessionId, video, prizes }) => {

    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId
    })

  return (
    <Dialog
        // eslint-disable-next-line no-mixed-operators
        open={transactionStatus.transactions && transactionStatus.transactions?.length > 0 || false}
        onClose={() => {
            if(transactionStatus.status === 'success' || transactionStatus.status === 'fail') {
                setSessionId('')
            }
        }}
        maxWidth="md"
        fullWidth
    >
        <Box sx={{
            padding: 2,
            minHeight: '250px'
        }}>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography sx={{ color: '#000000' }} variant='h4'>Transaction Status</Typography>
                <FontAwesomeIcon size='2x' icon={faTimesCircle} onClick={() => setSessionId('')} cursor='pointer' />
            </Stack>
            <Box position={'relative'}>
                <CardResultStatus
                    status={transactionStatus.status}
                    hash={transactionStatus.transactions ? transactionStatus.transactions[0].hash : ''}
                    video={video}
                    prizes={prizes}
                />
            </Box>
        </Box>
    </Dialog>
  )
})

const CardResultStatus: FC<PropsStatus> = observer(({ status, hash, video, prizes }) => {
    const [show, setShow] = React.useState(false);
    const [prize, setPrize] = React.useState<Prize | undefined>(undefined);

    useEffect(() => {
        const getSCResult = async () => {
            try {
                const { data } = await axios.get(MULTIVERSX_API + '/transactions/' + hash)
                const dataResultsFiltred = data.results.filter((result: any) => result.data !== undefined);
                const results = dataResultsFiltred.map((result: any) => decodeBase64(result.data))
                const resultInHex = results.find((res: any) => res.includes('6f6b'))
                const resultDecoded = resultInHex?.split("@").pop()!
                const result = Number(hexToNumber(resultDecoded))
                
                const prizeWon = prizes.find((lootboxPrize) => lootboxPrize.id === result)
                setPrize(prizeWon)
            } catch (error) {
                console.log(error)
            }
        }

        if(status === 'success') {
            getSCResult()
        }
    }, [status, hash, prizes])


    if(!prize) {
        return (
            <Box sx={{ height: '100%', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}>
                <Loading loadingText='Waiting for result...' />
            </Box>
        )
    }

    if(status === 'success' && prize) {
        return (
            <Box width={'100%'} sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <div
                    className='d-flex flex-column justify-content-center align-items-center'
                    style={{
                        height: '100%',
                        position: 'relative',
                        maxWidth: '400px'
                    }}
                >
                    <video width={'100%'} height={'100%'} autoPlay playsInline onEnded={() => { setShow(true); console.log('show')}}>
                        <source src={video} type="video/mp4" />
                    </video>
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        {show && (
                            <div>
                                {
                                    prize && (
                                        <div>
                                            <Typography variant='h3' sx={{ color: '#000000' }}>
                                                x{prize.amount} {prize.name}
                                            </Typography>
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    </div>
                </div>
            </Box>
        )
    }

    if(status === 'fail') {
        return (
            <>
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '150px', maxHeight: '200px' }}>
                    <FontAwesomeIcon icon={faTimesCircle} color={'red'} fontSize={'80px'} />
                    <span>Transaction failed</span>
                </div>
            </>
        )
    }

    return null
})

interface Props {
    sessionId: string
    setSessionId: Dispatch<SetStateAction<string>>
    video: string
    prizes: Prize[]
}

interface PropsStatus {
    status: string,
    hash: string
    video: string
    prizes: Prize[]
}

export default CardResult