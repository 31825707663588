import React, { FC } from 'react'
import { GameItemI } from '../../../types'
import { Box, Stack, Typography } from '@mui/material'

const GameItem: FC<Props> = ({ game }) => {
  return (
    <Box sx={{ display: 'flex', direction: 'column', justifyContent: 'center' }}>
        <Box sx={{  position: 'relative' }}>
            <img src={game.image} alt={game.name} width='100%' style={{ borderRadius: '24px' }} />
            <Box sx={{ position: 'absolute', left: 15, bottom: 0, mb: 1 }}>
                <Typography variant='body1' textTransform={'uppercase'} fontWeight={600}>
                    {game.name}
                </Typography>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <game.Icon sx={{ color: '#E3A102' }} />
                    <Typography variant='body2'>
                        {game.stage}
                    </Typography>
                </Stack>
            </Box>  
        </Box>
    </Box>
  )
}

interface Props {
    game: GameItemI,
}

export default GameItem