import React, { FC, useEffect, useState } from 'react'
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks'
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { decodeBase64 } from '@multiversx/sdk-dapp/utils';
import { hexToNumber } from 'muf-utils';
import { EXPLORER_URL } from '../../../config';
import { getUserTransactions } from '../hooks/getUserTransactions';
import { Box, Typography } from '@mui/material';
import { getShortAddress } from '../../../shared/utils';
import { Prize } from '../../../types';

interface Props {
    smartContract: string;
    prizes: Prize[]
}

const TransactionsLog: FC<Props> = ({ smartContract, prizes }) => {
  const { address } = useGetAccountInfo();
  const [transactions, setTransactions] = useState<any[]>([])
  const { success } = useGetActiveTransactionsStatus();

  useEffect(() => {
    const fetchData = async () => {
        const data = await getUserTransactions(address, smartContract);
        setTransactions(data.slice(0, 5));
    }
    fetchData()
  }, [success, smartContract, address])

  const getResult = (dataResults: any[]) => {
    if(!dataResults || dataResults.length === 0) return;

    const dataResultsFiltred = dataResults.filter((result: any) => result.data !== undefined);
    const results = dataResultsFiltred.map((result: any) => decodeBase64(result.data))
    const resultInHex = results.find((res: any) => res.includes('6f6b'))
    const resultDecoded = resultInHex?.split("@").pop()!
    const result = hexToNumber(resultDecoded)

    return Number(result);
  }

  return (
    <Box width={'100%'} mt={3}>
        <Typography sx={{ color: '#FFFF', mb: 2 }} variant='h3' textAlign={'center'}>Transactions Log</Typography>
          <Table className='mission-log' responsive>
            <thead>
              <tr>
                <th style={{ color: '#FFFF'}}>Date</th>
                <th style={{ color: '#FFFF'}}>Hash</th>
                <th style={{ color: '#FFFF'}}>Prize</th>
              </tr>
            </thead>
            <tbody>
              {
                transactions.map((transaction) => (
                    <tr key={transaction.txHash}>
                        <td style={{ color: '#FFFF'}}>
                            {new Date(transaction.timestamp * 1000).toLocaleDateString()}
                        </td> 
                        <td style={{ color: '#FFFF'}}>
                            <a
                                href={`${EXPLORER_URL}/transactions/${transaction.txHash}`}
                                target='_blank'
                                rel='noreferrer'
                                style={{
                                    color: 'unset'
                                }}
                                
                            >
                                {getShortAddress(transaction.txHash)} <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </a>
                        </td> 
                        <td style={{ color: '#FFFF'}}>
                            x{
                              prizes.find((prize) => prize.id === getResult(transaction.results))?.amount
                            }
                            {" "}
                            {
                              prizes.find((prize) => prize.id === getResult(transaction.results))?.name
                            }
                        </td> 
                    </tr>
                ))
              }
            </tbody>
          </Table>
    </Box>
  )
}

export default TransactionsLog