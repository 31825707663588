import React, { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import { Table } from 'react-bootstrap';
import { EXPLORER_URL, MULTIVERSX_API } from '../../../config';
import axios from 'axios';
import { Key } from '../../../types';
import { numberToHex } from 'muf-utils';

interface Props {
    smartContract: string
    lootboxKey: Key
}

const Rewards: FC<Props> = ({ smartContract, lootboxKey }) => {
    const [nfts, setNfts] = useState<any[]>([])
    const [tokens, setTokens] = useState<any[]>([])

    useEffect(() => {
        const fetchSCNfts = async () => {
            try {
                if(!smartContract) {
                    if(nfts.length > 0) setNfts([]);
                    return
                }
                const { data } = await axios.get(`${MULTIVERSX_API}/accounts/${smartContract}/nfts`)
                setNfts(data.filter((nft: any) => nft.identifier !== lootboxKey.identifier + '-' + numberToHex(lootboxKey.nonce)))
            } catch (error) {
                
            }
        }
        const fetchSCTokens= async () => {
            try {
                if(!smartContract) {
                    if(tokens.length > 0) setTokens([]);
                    return
                }
                const { data } = await axios.get(`${MULTIVERSX_API}/accounts/${smartContract}/tokens`)
                setTokens(data.filter((token: any) => token.identifier !== lootboxKey.identifier))
            } catch (error) {
                
            }
        }
        fetchSCNfts()
        fetchSCTokens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smartContract, lootboxKey])
  return (
    <Box width={'100%'} mt={3}>
        <Typography sx={{ color: '#FFFF', mb: 2 }} variant='h3' textAlign={'center'}>Rewards</Typography>
          <Table className='mission-log' responsive>
            <thead>
              <tr>
                <th style={{ color: '#FFFF'}}>Name</th>
                <th style={{ color: '#FFFF'}}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                nfts.length > 0 && 
                    nfts.map((nft) => (
                    <tr key={nft.identifier}>
                        <td style={{ color: '#FFFF'}}>
                            <a
                                href={`${EXPLORER_URL}/nfts/${nft.identifier}`}
                                target='_blank'
                                rel='noreferrer'
                                style={{
                                    color: 'unset'
                                }}
                                        
                            >
                                {nft.name}
                            </a>
                        </td> 
                        <td style={{ color: '#FFFF'}}>
                            {nft.balance}
                        </td>
                    </tr>
                ))
              }
              {
                tokens.length > 0 && 
                tokens.map((token) => (
                    <tr key={token.identifier}>
                        <td style={{ color: '#FFFF'}}>
                            <a
                                href={`${EXPLORER_URL}/tokens/${token.identifier}`}
                                target='_blank'
                                rel='noreferrer'
                                style={{
                                    color: 'unset'
                                }}            
                            >
                                {token.name}
                            </a>
                        </td> 
                        <td style={{ color: '#FFFF', width: '100%'}}>
                            {
                            (Number(token.balance) / Math.pow(10, token.decimals)).toLocaleString('en-En', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })
                            }
                        </td>
                    </tr>
                ))
              }
            </tbody>
          </Table>
    </Box>
  )
}

export default Rewards